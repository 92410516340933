import { Avatar, AvatarFallback, AvatarImage } from "../../ui/Avatar";
import { Button } from "../../ui/Button";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import getAuthState from "../../../hooks/getAuthState";
import { cn } from "../../../lib/utils";
import { Message } from "../../../types/ChatsInterface";
import { useUserStore } from "../../../zustandStore/useUserStore";
import { useQueryClient } from "@tanstack/react-query";
import { IoSend } from "react-icons/io5";
import { ScrollArea } from "../../ui/ScrollArea";
import { IoMdArrowRoundBack } from "react-icons/io";

interface ChatRoomInterface {
  messages: Message[];
  newMessage: any;
  setMessages: any;
}

const ChatRoom = ({ messages, newMessage, setMessages }: ChatRoomInterface) => {
  const [inputMsg, setInputMsg] = useState("");
  const [localChats, setLocalChats] = useState<any[]>([]);

  const selectedUser = useUserStore((state) => state.selectedUser);
  const setSelectedUser = useUserStore((state) => state.setSelectedUser);

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, localChats, scrollToBottom]);

  const typeOfConversation = () => {
    if (selectedUser?.users) {
      const recipient = selectedUser.users.find(
        (user: any) => user.guid !== getAuthState().guid
      );
      return recipient;
    } else {
      return selectedUser;
    }
  };

  const queryClient = useQueryClient();

  const handleSendMessage = useCallback(() => {
    if (inputMsg.trim() === "") return;
    const optimisticMsge = [
      {
        type: "new_message",
        user_guid: getAuthState().guid,
        chat_guid: selectedUser?.chat_guid,
        content: inputMsg,
      },
    ];
    setLocalChats((prev) => [...prev, ...optimisticMsge]);
    newMessage(inputMsg);
    setInputMsg("");
  }, [inputMsg, newMessage, selectedUser?.chat_guid]);

  const memoizedMessages = useMemo(() => {
    const uniqueMessageGuids = new Set();
    setLocalChats([]);
    return messages.filter((msg: any) => {
      if (uniqueMessageGuids.has(msg.message_guid)) {
        return false;
      }
      uniqueMessageGuids.add(msg.message_guid);
      return true;
    });
  }, [messages]);

  return (
    <>
      <div className="h-16 border-b border-unimaytLight flex items-center justify-between bg-unimaytLight md:rounded-t-2xl">
        <div className="flex items-center gap-x-0 my-4">
          <Button
            onClick={() => {
              setMessages([]);
              queryClient.invalidateQueries({ queryKey: ["chats"] });
              setSelectedUser(null);
            }}
            variant="ghost"
            size="icon"
            className="h-9 w-9 font-heading hover:bg-unimaytLight"
          >
            <IoMdArrowRoundBack className="h-5 w-5 text-light" />
          </Button>

          <Avatar className="h-8 w-8 border-2 border-white rounded-full">
            {typeOfConversation()?.user_image ? (
              <AvatarImage
                src={typeOfConversation().user_image}
                alt={typeOfConversation()?.first_name || "Profile"}
                className="w-full h-full rounded-full object-cover"
              />
            ) : (
              <AvatarFallback className="border h-full w-full font-heading text-lg border-light rounded-full">
                <img
                  src={
                    typeOfConversation()?.gender === "Female"
                      ? "/icons/female.png"
                      : "/icons/male.png"
                  }
                  alt="Profile"
                  className="w-full h-full rounded-full object-cover"
                />
              </AvatarFallback>
            )}
          </Avatar>

          <div>
            <h3 className="text-paragraph font-semibold ml-2 text-light">
              {typeOfConversation()?.first_name}
            </h3>
            {/* <p className="text-subparagraph text-muted-foreground">
              {typeOfConversation()?.username}
            </p> */}
          </div>
        </div>
      </div>

      {/* {messages.length === 0 ? (
        <div className="flex items-center justify-center">
          <img
            src={"/icons/ripples.svg"}
            alt={"loading"}
            height={1}
            width={1}
            className="w-20 h-20 object-contain"
          />
        </div>
      ) : ( */}
      <ScrollArea className="flex-grow bg-light">
        <div className="flex flex-1 flex-col gap-2 rounded-md px-2 pt-2">
          <div className="flex size-full flex-1">
            <div className="relative -mr-4 flex flex-1 flex-col-reverse overflow-y-hidden">
              <div className="flex h-full w-full flex-col justify-end gap-y-1 pb-2 pr-4">
                {[
                  ...memoizedMessages,
                  ...localChats, // Spread localChats
                ].map(
                  (message: any, index: number) =>
                    message &&
                    message.content &&
                    message.content.trim() !== "" && (
                      <div
                        key={`${message.sender}-${message.timestamp}-${index}`}
                        className={cn(
                          "chat-box max-w-72 break-words px-3 py-2 shadow-lg",
                          message.user_guid === getAuthState().guid
                            ? "self-end rounded-[16px_16px_0_16px] bg-primary/85 text-primary-foreground/75"
                            : "self-start rounded-[16px_16px_16px_0] mb-1 bg-secondary"
                        )}
                      >
                        <div>{message.content}</div>
                      </div>
                    )
                )}
                <div ref={scrollRef} />
              </div>
            </div>
          </div>
        </div>
      </ScrollArea>
      {/* )} */}
      <div className="bg-Gray rounded-2xl border border-Gray my-2 mx-1 lg:m-1">
        <div className="flex items-center justify-center">
          <input
            type="text"
            placeholder="Type a message..."
            value={inputMsg}
            onChange={(e) => setInputMsg(e.target.value)}
            className="flex-grow ml-1 bg-Gray outline-none"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
          />
          <div className="bg-dark rounded-lg flex items-center justify-center m-1 w-14 h-9 p-4">
            <button
              onClick={() => {
                handleSendMessage();
              }}
              className="text-light"
            >
              <IoSend className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatRoom;
