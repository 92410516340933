import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getAuthState from "./hooks/getAuthState";

const AuthProviders = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isAuthenticated = getAuthState();
    if (!isAuthenticated && location.pathname !== "/login") {
      navigate("/login");
    }
  }, [location.pathname, navigate]);

  const checkTokenExpire = () => {
    if (typeof window !== "undefined") {
      const expString = localStorage.getItem("exp");
      const exp = expString ? new Date(expString) : null;
      const currentTime = new Date();
      if (expString && exp) {
        return currentTime.getTime() < exp.getTime();
      }
    }
    return false;
  };

  useEffect(() => {
    if (!checkTokenExpire() && location.pathname !== "/login") {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("exp");
      navigate("/login");
    }
  }, [location.pathname, navigate]);

  return <>{children}</>;
};

export default AuthProviders;
