import { z } from "zod";

export const UpdateSchema = z.object({
  university_name: z
    .string()
    .min(1, "University name is required")
    .nullable() 
    .optional(),
  campus_location: z
    .string()
    .min(1, "Campus location is required")
    .optional(),
  course_name: z
    .string()
    .min(1, "Course name is required")
    .optional(),
  course_start_date: z
    .date({ required_error: "Course Intake must be a valid date" })
    .nullable() 
    .optional(),
  city_name: z
    .string()
    .min(1, "Home location is required")
    .optional(),
}).superRefine((data, ctx) => {
  const universityOption = data.university_name;

  if (
    universityOption === "I have an acceptance letter" ||
    universityOption === "I have multiple acceptance letters"
  ) {
    if (!universityOption) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "University name is required when you have an acceptance letter",
        path: ['university_name'],
      });
    }

    if (!data.course_start_date) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Course start date is required when you have an acceptance letter",
        path: ['course_start_date'],
      });
    }
  }
});
