import React from "react";
import getAuthState from "../hooks/getAuthState";
import SecondaryHeader from "../components/layout/header/SecondaryHeader";
import MessagingWindow from "../components/shared/chat/MessagingWindow";
import { PiChatsBold } from "react-icons/pi";
import MiniJourney from "../components/shared/miniJourney";
import { useUserStore } from "../zustandStore/useUserStore";
import { getStoredConversation } from "../lib/conversation";
import Footer from "../components/layout/Footer";

const Journey: React.FC = () => {
  const setChatRoomOpen = useUserStore((state) => state.setChatRoomOpen);
  const chatRoomOpen = useUserStore((state) => state.chatRoomOpen);

  if (!getAuthState()) return <div>Loading...</div>;
  return (
    <div className="flex flex-col min-h-screen bg-[#f1f5fb]">
      <div className="sticky top-0 z-10">
        <SecondaryHeader dropdown search />
      </div>

      <div className="flex flex-1 gap-x-2 sm:px-4 lg:px-2 py-2">
        <div className="md:!w-9/12 w-full overflow-y-scroll no-scrollbar">
          <MiniJourney />
        </div>

        <div className="w-3/12 hidden md:block lg:block fixed right-2 top-16 bottom-1 mt-1 bg-white">
          <MessagingWindow conversation={getStoredConversation()} />
        </div>

        <div
          className={`${
            chatRoomOpen
              ? "fixed inset-0 z-50 w-full h-full bg-white"
              : "hidden"
          } lg:hidden`}
        >
          <div className="relative h-full">
            <MessagingWindow conversation={getStoredConversation()} />
          </div>
        </div>
      </div>
      {!chatRoomOpen && (
        <button
          className="fixed bottom-6 right-3 lg:hidden bg-Gray text-dark p-3 rounded-full shadow-lg flex flex-row gap-x-2"
          onClick={() => setChatRoomOpen(getStoredConversation()[0])}
        >
          <PiChatsBold size={25} />
        </button>
      )}
      <div className="md:w-9/12 w-full md:p-2">
        <Footer />
      </div>
    </div>
  );
};

export default Journey;
