import Login from "../../pages/auth/Login";
import getAuthState from "../../hooks/getAuthState";
import Profile from "../../pages/Home";

const Landing = () => {
  const authState = getAuthState();

  return <div>{authState ? <Profile /> : <Login />}</div>;
};

export default Landing;
