import React, { useState } from "react";
import SecondaryHeader from "../../layout/header/SecondaryHeader";
import Footer from "../../layout/Footer";
import TrustedWidget from "../../shared/parterWidgets/TrustedWidget";

const PartnerWidget = () => {
  const [showTripPartner, setShowTripPartner] = useState(false);

  const handleClick = () => {
    setShowTripPartner(true);
  };

  return (
    <div className="w-full h-screen bg-light">
      {/* Header */}
      <div className="sticky top-0 z-10">
        <SecondaryHeader dropdown search />
      </div>

      <div className="bg-light px-2 lg:px-4 lg:py-6 pt-3">
        <div className="text-center">
          <h1 className="text-smheading lg:text-heading font-heading text-dark mb-2">
            Our Partners
          </h1>
          <p className="md:text-subheading text-paragraph text-dark mb-0">
            We collaborate with industry leaders to bring you the best travel
            experiences.
          </p>
        </div>
      </div>

      <TrustedWidget />

      {/* Footer */}
      <div className="md:p-2 bg-white">
        <Footer />
      </div>
    </div>
  );
};

export default PartnerWidget;
