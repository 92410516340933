import { useForm } from "react-hook-form";
import { Input } from "../../ui/Input";
import { Label } from "../../ui/Label";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../ui/card";
import SecondaryHeader from "../../layout/header/SecondaryHeader";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { resetSchema } from "../../../zodSchema/ResetSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import UseAuth from "./../../../services/useAuth";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useState } from "react";

type LoginSchemaType = z.infer<typeof resetSchema>;

const EnterEmail = () => {
  const navigate = useNavigate();

  const { requestResetPassword } = UseAuth();

  const resetMutation = useMutation({
    mutationFn: requestResetPassword,
    onSuccess: async (data) => {},
    onError: (err: any) => {
      toast.error(`${err.response.data.detail}`);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginSchemaType>({
    resolver: zodResolver(resetSchema),
  });

  const onSubmit = (data: z.infer<typeof resetSchema>) => {
    resetMutation.mutate(data.email);
  };

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div className="sticky top-0 z-10">
        <SecondaryHeader />
      </div>

      <div className="flex-grow flex items-center justify-center px-4">
        <Card className="w-full max-w-md p-2 rounded-2xl shadow-2xl">
          <CardHeader>
            <CardTitle className="text-center">Trouble Signing in?</CardTitle>
            <CardDescription className="md:pt-4 pt-2 text-center">
              We’ll send a verification code to this email if it matches an
              existing Unimayt account.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col items-center"
            >
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    placeholder="Enter your email"
                    {...register("email", { required: true })}
                    className="w-full"
                  />
                  {errors.email && (
                    <p className="error-message">{errors.email.message}</p>
                  )}
                </div>
              </div>
              {resetMutation.isPending ? (
                <div className="w-full flex py-2 items-center justify-center">
                  <img
                    src="/icons/ripples.svg"
                    alt="loading"
                    className="w-12 h-12 z-10"
                  />
                </div>
              ) : resetMutation.isSuccess ? (
                <div className="text-green-600 mx-20 w-full text-base my-5 py-2 border-unimayt rounded-3xl flex items-center justify-center">
                  Check your inbox or spam!
                </div>
              ) : (
                <button
                  type="submit"
                  className="hover:scale-105 cursor-pointer duration-200 btn ease-in-out transition-all w-full bg-Button mx-28 my-5 rounded-3xl"
                >
                  Next
                </button>
              )}
            </form>

            <div
              onClick={() => {
                navigate("/login");
              }}
              className="hover:scale-105 duration-200 cursor-pointer btn ease-in-out transition-all w-full btn bg-light border text-dark hover:bg-Gray rounded-3xl"
            >
              Back
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default EnterEmail;
