import { z } from "zod";

export const signupSchema = z
  .object({
    first_name: z.string().min(1, "First Name is Required"),
    email: z
      .string()
      .email("Invalid Email Address")
      .min(1, "Email Address is Required"),
    
    password: z.string()
    .min(8, { message: "Password must be at least 8 characters long" })
    .max(20, { message: "Password must be at most 20 characters long" })
    .regex(/[A-Z]/, { message: "Password must contain at least one uppercase letter" })
    .regex(/[a-z]/, { message: "Password must contain at least one lowercase letter" })
    .regex(/\d/, { message: "Password must contain at least one number" })
    .regex(/[@$!%*?&#]/, { message: "Password must contain at least one special character" }),
    confirmPassword: z.string().min(1, "Confirm Password is Required"),
    gender: z.enum(["Male", "Female", "Prefer not to say"], {
      required_error: "Gender is Required",
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });
