import React, { useState } from "react";

interface EditBioModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (bio: string) => void;
  bio: string;
}

const EditBioModal: React.FC<EditBioModalProps> = ({
  isOpen,
  onClose,
  onSave,
  bio,
}) => {
  const [bioText, setBioText] = useState(bio);
  const [charCount, setCharCount] = useState(bio.length);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    if (newText.length <= 300) {
      setBioText(newText);
      setCharCount(newText.length);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(bioText);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50 backdrop-blur-sm">
      <div className="relative bg-unimaytLight p-4 rounded-lg w-full max-w-lg md:w-100 h-auto m-2 pt-12 lg:-mb-4">
        <button
          onClick={onClose}
          className="absolute top-3 right-4 text-white text-xl font-bold"
        >
          &times;
        </button>

        <h2 className="mb-2 text-center text-smheading font-heading text-light">
          Edit Bio
        </h2>
        <form onSubmit={handleSubmit}>
          <textarea
            value={bioText}
            onChange={handleChange}
            className="w-full mt-1 border p-2 rounded-lg text-black h-24 resize-none outline-none"
            placeholder="Type your bio here..."
          />
          <div className="flex justify-between items-center mt-2 ml-2">
            <span className="text-subparagraph font-semibold text-light">
              {charCount}/300
            </span>
            <button type="submit" className="py-2 px-4 w-1/3 btn">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBioModal;
