import { useCallback, useEffect, useState } from "react";
import getAuthState from "../../../hooks/getAuthState";
import { Message } from "../../../types/ChatsInterface";
import { getWebSocketURL } from "../../../hooks/getWebSocketUrl";
import UseChat from "../../../services/useChat";
import { SearchIcon, XIcon } from "lucide-react";
import { Input } from "../../ui/Input";
import ConversationList from "./ConversationList";
import ChatRoom from "./ChatRoom";
import { useUserStore } from "../../../zustandStore/useUserStore";
import { ScrollArea } from "../../ui/ScrollArea";
import { cn } from "../../../lib/utils";

interface MessagingWindowProps {
  setConversation?: any;
  conversation: any;
  chat_guid?: string;
}

const MessagingWindow: React.FC<MessagingWindowProps> = ({
  setConversation,
  conversation,
  chat_guid,
}: any) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);

  const { getMessages } = UseChat();

  const setChatRoomOpen = useUserStore((state) => state.setChatRoomOpen);
  const chatRoomOpen = useUserStore((state) => state.chatRoomOpen);

  const chatGuid = useUserStore((state) => state.chatGuid);

  const selectedUser = useUserStore((state) => state.selectedUser);
  const setSelectedUser = useUserStore((state) => state.setSelectedUser);

  const sendMessage = useCallback(
    (content: string) => {
      if (!chatRoomOpen || !content.trim() || !socket || !getAuthState())
        return;
      const messageData = {
        type: "new_message",
        user_guid: getAuthState().guid,
        chat_guid: chatRoomOpen.chat_guid ? chatRoomOpen.chat_guid : chatGuid,
        content: content.trim(),
      };
      socket.send(JSON.stringify(messageData));
    },
    [chatGuid, chatRoomOpen, socket]
  );

  const readMessage = useCallback(
    (chat_guid: string, message_guid: string) => {
      if (!chat_guid || !socket || !message_guid || !getAuthState()) return;
      if (socket.readyState === WebSocket.OPEN) {
        const messageData = {
          type: "message_read",
          chat_guid: chat_guid,
          message_guid: message_guid,
        };
        socket.send(JSON.stringify(messageData));
      }
    },
    [socket]
  );

  const fetchMessages = useCallback(async (chatGuid: string) => {
    if (!chatGuid) {
      return;
    }
    try {
      const response = await getMessages(chatGuid);

      if (response?.data?.messages) {
        const unreadMessages = response.data.messages.filter(
          (message: Message) =>
            message.user_guid !== getAuthState().user_guid && !message.is_read
        );

        unreadMessages.forEach((message: Message) =>
          readMessage(chatGuid, message.message_guid)
        );

        setMessages(response.data.messages);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (getAuthState()) {
      let ws: WebSocket | null = null;

      const connectWebSocket = () => {
        ws = new WebSocket(getWebSocketURL());

        ws.onopen = () => {
          console.log("Connect");
          setSocket(ws);
        };

        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data) {
            setMessages((prevMessages) => [...prevMessages, data]);
          }
        };

        ws.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        ws.onclose = () => {
          console.log("WebSocket is closed, reconnecting...");
          setTimeout(connectWebSocket, 100);
        };
      };

      connectWebSocket();

      return () => {
        if (ws) ws.close();
      };
    }
  }, [setConversation, chatRoomOpen?.chat_guid]);

  const OpenSelectedChatRoom = useCallback(
    async (user: any) => {
      if (!user) return;
      if (user && user.chat_guid) {
        fetchMessages(user.chat_guid);
        setChatRoomOpen(user);
      }
      if (user && user.chat_guid === undefined && chatGuid) {
        fetchMessages(chatGuid);
        setChatRoomOpen(user);
      }
    },
    [fetchMessages]
  );

  const [hasOpenedChat, setHasOpenedChat] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (selectedUser !== null && !hasOpenedChat) {
        OpenSelectedChatRoom(selectedUser);
        setHasOpenedChat(true);
      }
    }
  }, [selectedUser, OpenSelectedChatRoom, hasOpenedChat]);

  useEffect(() => {
    if (selectedUser !== null) {
      setHasOpenedChat(false);
    }
  }, [selectedUser]);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredChats = conversation?.filter((chat: any) =>
    chat.users.some((user: any) =>
      user.first_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const isMobile = window.innerWidth <= 768;

  return (
    <div className="h-full w-full border border-gray-400 md:rounded-2xl flex flex-col">
      {selectedUser === null ? (
        <>
          <div className="h-16 border-b border-gray-300 font-medium md:text-smheading text-subheading flex items-center justify-center bg-dark text-light p-4 md:rounded-t-2xl relative">
            <button
              className="absolute top-1/2 right-3 transform -translate-y-1/2 text-white text-lg lg:hidden"
              onClick={() => setChatRoomOpen(null)}
            >
              <XIcon className="font-heading hover:bg-unimaytLight" />
            </button>
            Messaging
          </div>

          <div className="p-4 border-b border-gray-300">
            <div className="relative">
              <SearchIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
              <Input
                type="text"
                placeholder="Search messages"
                className=" bg-Gray"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <ScrollArea
            className={`flex-grow ${selectedUser && isMobile ? "hidden" : ""}`}
          >
            {filteredChats?.map((chat: any) => (
              <div
                onClick={() => {
                  setSelectedUser(chat);
                }}
                key={chat.chat_guid}
              >
                {chat?.users?.map((user: any) =>
                  user.guid !== getAuthState().guid ? (
                    <div key={user.guid}>
                      <ConversationList user={user} />
                    </div>
                  ) : null
                )}
              </div>
            ))}
          </ScrollArea>
        </>
      ) : (
        <ChatRoom
          newMessage={sendMessage}
          messages={messages}
          setMessages={setMessages}
        />
      )}
    </div>
  );
};

export default MessagingWindow;
