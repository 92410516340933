import connected_people from "../assets/Images/WebImages/AboutCrousals/connected_people.png";
import about_crousal from "../assets/Images/WebImages/AboutCrousals/about_crousal.png";
import community_network from "../assets/Images/WebImages/AboutCrousals/community_network.png";
import engaged from "../assets/Images/WebImages/AboutCrousals/engaged.png";
import { useLocation } from "react-router-dom";
import SecondaryHeader from "../components/layout/header/SecondaryHeader";
import Footer from "../components/layout/Footer";

const About = () => {
  const location = useLocation();

  const isAdditional = () => {
    return location.pathname === "/about";
  };

  return (
    <>
      {isAdditional() && (
        <div className="fixed top-0 left-0 w-full z-50">
          <SecondaryHeader search dropdown />
        </div>
      )}

      <div className="bg-unimaytLight lg:px-20">
        <div className="container mx-auto pb-4 pt-12 lg:pb-1 lg:pt-28">
          {/* Section 1 */}
          <div className="flex flex-col md:flex-row items-center mb-12 group">
            <div className="md:w-1/2">
              <img
                src={connected_people}
                alt="Connected People"
                className="rounded-lg w-full h-auto transition-transform duration-500 transform group-hover:rotate-3 hidden lg:block"
              />
            </div>
            <div className="md:w-full md:pl-10 mt-4 md:mt-0 text-left text-white">
              <h2 className="text-smheading lg:text-heading font-heading mb-4 text-dark">
                About Us...
              </h2>
              <p className="text-paragraph text-light transition-transform duration-500 transform group-hover:scale-105">
                Unimayt is a revolutionary platform dedicated to connecting
                students with universities worldwide. We bridge the gap between
                aspiring students and educational institutions, making the
                university application process seamless and stress-free. Whether
                you're looking for the right university or connecting with
                future classmates, Unimayt is here to guide you every step of
                the way.
              </p>
            </div>
          </div>

          {/* Section 2 */}
          <div className="flex flex-col md:flex-row-reverse items-center mb-12 group">
            <div className="md:w-1/2">
              <img
                src={about_crousal}
                alt="About Crousal"
                className="rounded-lg w-full h-auto transition-transform duration-1000 transform group-hover:scale-110 group-hover:-rotate-3 group-hover:translate-y-1"
              />
            </div>
            <div className="md:w-full md:pr-10 mt-1 md:mt-0 text-right">
              <h2 className="text-smheading lg:text-heading font-heading mb-4 text-dark">
                Our Mission...
              </h2>
              <p className="text-paragraph text-light transition-transform duration-500 transform group-hover:scale-105">
                Our mission is to empower students by providing them with the
                tools, resources, and connections they need to achieve their
                educational dreams. We aim to simplify the journey to higher
                education by creating a platform that supports students and
                universities alike, fostering a community of learners and
                educators.
              </p>
            </div>
          </div>

          {/* Section 3 */}
          <div className="flex flex-col md:flex-row items-center mb-12 group">
            <div className="md:w-1/2">
              <img
                src={community_network}
                alt="Community Network"
                className="rounded-lg w-full h-auto transition-transform duration-500 transform group-hover:scale-110 group-hover:rotate-3 group-hover:translate-y-1"
              />
            </div>
            <div className="md:w-full md:pl-10 mt-8 md:mt-0 text-left">
              <h2 className="text-smheading lg:text-heading font-heading mb-4 text-dark">
                Our Offerings...
              </h2>
              <p className="text-paragraph text-light transition-transform duration-500 transform group-hover:scale-105">
                <ul className="list-disc list-inside">
                  <li>
                    <span className="font-heading">
                      University Connections:
                    </span>
                    <br />
                    Find the perfect university that matches your academic and
                    career goals.
                  </li>
                  <li>
                    <span className="font-heading">Classmate Networking:</span>
                    <br />
                    Connect with future classmates and start building your
                    academic community before you even step on campus.
                  </li>
                  <li>
                    <span className="font-heading">Exam Visibility:</span>
                    <br />
                    Showcase your exam results to universities, increasing your
                    chances of acceptance even if you haven't received an offer
                    yet.
                  </li>
                  <li>
                    <span className="font-heading">Expert Guidance:</span>
                    <br />
                    Access personalized advice and support from educational
                    consultants to navigate the complex landscape of university
                    applications.
                  </li>
                </ul>
              </p>
            </div>
          </div>

          {/* Section 4 */}
          <div className="flex flex-col md:flex-row-reverse items-center mb-12 group">
            <div className="md:w-1/2">
              <img
                src={engaged}
                alt="Engaged"
                className="rounded-lg w-full h-auto transition-transform duration-1000 transform group-hover:scale-110 group-hover:-rotate-3 group-hover:translate-y-1"
              />
            </div>
            <div className="md:w-full md:pr-10 mt-8 md:mt-0 text-right">
              <h2 className="text-smheading lg:text-heading font-heading mb-4 text-dark">
                Our Vision...
              </h2>
              <p className="text-paragraph text-light transition-transform duration-500 transform group-hover:scale-105">
                We envision a world where every student has access to the best
                educational opportunities, regardless of their location or
                background. By connecting students and universities globally, we
                strive to create a more inclusive and accessible higher
                education system.
              </p>
            </div>
          </div>

          {/* Section 5 */}
          <div className="flex flex-col md:flex-row items-center mb-12 group">
            <div className="md:w-1/2">
              <img
                src={connected_people}
                alt="Connected People"
                className="rounded-lg w-full h-auto transition-transform duration-1000 transform group-hover:scale-110 group-hover:-rotate-3 group-hover:translate-y-1 pt-8"
              />
            </div>
            <div className="md:w-full md:pl-12 mt-4 md:mt-0 text-left pt-6">
              <h2 className="text-smheading lg:text-heading font-heading mb-4 text-dark">
                Join Us...
              </h2>
              <p className="text-paragraph text-light transition-transform duration-500 transform group-hover:scale-105">
                Are you passionate about education and technology? Do you want
                to make a difference in the lives of students around the world?
                Join us at Unimayt and be part of a team that's transforming the
                future of education. Explore career opportunities with us and
                contribute to a mission that truly matters.
              </p>
            </div>
          </div>
        </div>
      </div>
      {isAdditional() && (
        <div className="md:p-2 bg-unimaytLight">
          <Footer />
        </div>
      )}
    </>
  );
};

export default About;
