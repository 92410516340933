import { z } from "zod";

export const loginSchema = z.object({
    username: z.string().email("Invalid Email Address").min(1,"Email Address is Required"),
    password: z
    .string()
    // .min(8, { message: "must be at least 8 characters long" })
    // .max(20, { message: "must be at most 20 characters long" }) // Optional: setting a max length
    // .regex(/[A-Z]/, { message: "must contain at least one uppercase letter" })
    // .regex(/[a-z]/, { message: "must contain at least one lowercase letter" })
    // .regex(/\d/, { message: "must contain at least one number" })
    // .regex(/[@$!%*?&#]/, {
    // message: "must contain at least one special character",
    // }),
})