import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { z } from "zod";
import googlelogo from "../../assets/Logos/googlelogo.svg";
import applelogo from "../../assets/Logos/applelogo.svg";
import { useState } from "react";
import { signupSchema } from "../../zodSchema/signupSchema";
import UseAuth from "../../services/useAuth";
import { HiEye, HiEyeOff, HiOutlineAtSymbol } from "react-icons/hi";
import { FaLock } from "react-icons/fa";

type SignupSchema = z.infer<typeof signupSchema>;

const SignupModal = () => {
  const navigate = useNavigate();
  const { registerUser } = UseAuth();
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupSchema>({
    resolver: zodResolver(signupSchema),
  });

  const onSubmit = (data: SignupSchema) => {
    if (!isChecked) {
      toast.error("Agree to the given terms and conditions.");
      return;
    }

    const obj = {
      password: data.password,
      first_name: data.first_name,
      email: data.email,
      gender: data.gender,
    };
    signupMutation.mutate(obj);
  };

  const signupMutation = useMutation({
    mutationFn: registerUser,
    onSuccess: async (data) => {
      localStorage.setItem("userInfo", JSON.stringify(data));
      toast.success("Signup Successful");
      navigate("/home", { replace: true });
    },
    onError: (error: any) => {
      if (error.response && error.response.status === 409) {
        toast.error("User already exists, sign up with another email.");
      } else {
        toast.error(error.message || "An error occurred. Please try again.");
      }
    },
  });

  const handleGoogleSignup = async () => {
    console.error("Google signup not implemented yet.");
  };

  const handleAppleSignup = async () => {
    console.error("Apple signup not implemented yet.");
  };

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className="text-center md:pt-2 pt-4">
        <h2 className="text-2xl font-heading text-light">Create an Account</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="md:space-y-0">
        <div className="flex justify-center mt-4">
          <div className="w-11/12">
            <label className="block text-sm font-semibold text-light">
              Full Name
            </label>
            <div className="input-container">
              <input
                type="text"
                placeholder="Enter your Full Name"
                className="input-field"
                {...register("first_name")}
              />
            </div>
            {errors.first_name && (
              <p className="text-red-500 text-xs">
                {errors.first_name.message}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <div className="w-11/12 pt-3">
            <label className="block text-base font-semibold text-light">
              Email
            </label>
            <div className="input-container">
              <HiOutlineAtSymbol className="w-5 h-5 text-gray-400" />
              <input
                type="email"
                placeholder="Enter your email address"
                className="input-field"
                {...register("email")}
              />
            </div>
            {errors.email && (
              <p className="text-red-500 text-xs">{errors.email.message}</p>
            )}
          </div>
        </div>

        {/* <div className="flex justify-center">
          <div className="w-11/12">
            <label className="block text-sm font-semibold text-white">
              Username
            </label>
            <input
              type="text"
              className="w-full mt-1 border p-2 rounded-lg"
              {...register("username")}
            />
            {errors.username && (
              <p className="text-red-500 text-xs">{errors.username.message}</p>
            )}
          </div>
        </div> */}

        <div className="flex justify-center">
          <div className="relative w-11/12 pt-3">
            <label className="block text-base font-semibold text-light">
              Enter Password
            </label>
            <div className="input-container">
              <FaLock className="w-4 h-4 md:h-5 text-gray-400" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter the Password"
                className="input-field"
                {...register("password")}
              />
            </div>
            <span
              className="absolute right-2 top-14 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <HiEyeOff className="text-gray-500" />
              ) : (
                <HiEye className="text-gray-500" />
              )}
            </span>
            {errors.password && (
              <p className="text-red-500 text-xs">{errors.password.message}</p>
            )}
          </div>
        </div>

        {/* Confirm Password Field */}
        <div className="flex justify-center">
          <div className="relative w-11/12 pt-3">
            <label className="block text-base font-semibold text-light">
              Confirm Password
            </label>
            <div className="input-container">
              <FaLock className="w-4 h-4 md:h-5 text-gray-400" />
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm the given password"
                className="input-field"
                {...register("confirmPassword")}
              />
            </div>
            <span
              className="absolute right-2 top-14 cursor-pointer"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <HiEyeOff className="text-gray-500" />
              ) : (
                <HiEye className="text-gray-500" />
              )}
            </span>
            {errors.confirmPassword && (
              <p className="text-red-500 text-xs">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <div className="w-11/12 pt-3 pl-1">
            <label className="block text-base font-semibold text-light">
              Gender
            </label>
            <div className="flex items-center mt-1 space-x-4">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="Male"
                  {...register("gender")}
                  className="form-radio text-Button"
                />
                <span className="ml-2 text-light">Male</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="Female"
                  {...register("gender")}
                  className="form-radio text-Button"
                />
                <span className="ml-2 text-light">Female</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="Prefer not to say"
                  {...register("gender")}
                  className="form-radio text-Button"
                />
                <span className="ml-2 text-light">Prefer not to say</span>
              </label>
            </div>
            {errors.gender && (
              <p className="text-red-500 text-xs">{errors.gender.message}</p>
            )}
          </div>
        </div>

        <div className="flex text-[11px] pt-3 md:pl-4">
          <div
            onClick={handleCheckboxClick}
            className={`w-6 lg:w-4 h-4 border border-unimayt ml-4 mr-2 flex justify-center items-center cursor-pointer ${isChecked ? "bg-Button" : "bg-light"}`}
          >
            {isChecked && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 text-light font-heading"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            )}
          </div>
          By clicking Agree & Join or Continue, you agree to the Unimayt User
          Agreement, Privacy Policy, and Cookie Policy.
        </div>

        <div className="flex justify-center">
          <div className="w-11/12 pt-3">
            <button type="submit" className="btn">
              Submit
            </button>
          </div>
        </div>

        <div className="mt-4 text-center md:pt-3 pt-0">
          <p className="text-sm font-semibold text-white flex items-center">
            <span className="w-11/12 border-b border-solid border-extralight"></span>
            <span className="px-2 text-paragraph pb-2 text-extralight">or</span>
            <span className="w-11/12 border-b border-solid border-extralight"></span>
          </p>
        </div>

        <div className="flex justify-center md:mt-3 pt-0 md:pb-0 pb-4">
          <div className="w-11/12 flex flex-row items-center space-x-2 pt-3">
            <button onClick={handleGoogleSignup} className="btn">
              <img
                src={googlelogo}
                alt="Google logo"
                className="w-4 h-3 mr-1 md:mr-3"
              />
              <span className="text-extralight font-semibold text-center text-[13px] md:text-subparagraph leading-[22px]">
                Sign up with Gmail <br />{" "}
                <div className="md:text-[13px] text-[10px]">(Coming Soon)</div>
              </span>
            </button>
            <button onClick={handleAppleSignup} className="btn">
              <img
                src={applelogo}
                alt="Apple logo"
                className="w-5 h-5 mr-1 md:mr-3"
              />
              <span className="text-extralight font-semibold text-center text-[13px] md:text-subparagraph leading-[22px]">
                Sign up with Apple{" "}
                <div className="md:text-[13px] text-[10px]">(Coming Soon)</div>
              </span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default SignupModal;
