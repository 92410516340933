import Footer from "../Footer";
import SecondaryHeader from "../header/SecondaryHeader";

const PrivacyPolicies = () => {
  return (
    <div>
      <SecondaryHeader search dropdown />

      <div className="container mx-auto px-4 sm:px-8 md:px-12 lg:px-24 xl:px-48 py-0 sm:py-8 md:py-0">
        <h1 className="flex items-center justify-center text-smheading md:text-heading font-heading mb-4 sm:mb-10 lg:mb-4 mt-2 text-dark">
          Privacy Policy
        </h1>
        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Effective Date: 16th September, 2024
        </h2>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          At Unimayt, we value your privacy and are committed to protecting your
          personal data. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you visit our website
          and use our services, including our mobile application (the "App"). By
          using our services, you agree to the practices described in this
          policy.
        </p>

        <h2 className="text-subheading font-heading mb-4 text-dark ml-4 md:ml-0">
          1. Information We Collect
        </h2>
        <h1 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          1.1 Personal Data You Provide
        </h1>
        <ul className="list-disc list-inside text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          <li>
            <strong>Profile Information: </strong>
            <span>
              Name, email address, password, date of birth, gender, city of
              origin, country of origin, degree program, and other details you
              choose to provide.
            </span>
          </li>
          <li>
            <strong>Social Media Data: </strong>
            <span>
              If you use a social media login, we may receive basic information
              from the social media provider, such as your email address.
            </span>
          </li>
          <li>
            <strong>Profile Content: </strong>
            <span>
              Any additional information, including profile pictures and
              background details, that you choose to add to your profile.
            </span>
          </li>
        </ul>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          1.2 Data from Higher Education Institutions
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          If your institution uses our platform, we may receive personal data
          from them, such as your name, email address, and other relevant
          details prefilled by the institution.
        </p>
        <h1 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          1.3 Usage Data
        </h1>
        <ul className="list-disc list-inside text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          <li>
            <strong>App Usage: </strong>
            <span>Frequency, duration, and activity within the App.</span>
          </li>
          <li>
            <strong>Communication Data: </strong>
            <span>
              Messages and content shared within the App, including chat
              messages, posts, and flags for inappropriate content.
            </span>
          </li>
        </ul>
        <h1 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          1.4 Technical Data
        </h1>
        <ul className="list-disc list-inside text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          <li>
            <strong>Device Information: </strong>
            <span>
              IP address, device type, operating system, and browser type.
            </span>
          </li>
          <li>
            <strong>Log Data: </strong>
            <span>
              Information about your use of our services, including access
              times, error logs, and other diagnostic data.
            </span>
          </li>
        </ul>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          2. How We Use Your Information
        </h2>
        <p className="text-subparagraph mb-2 text-dark ml-4 md:ml-0">
          We use your personal data for the following purposes:
        </p>
        <ul className="list-disc list-inside text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          <li>
            <strong>Account Management: </strong>
            <span>
              To create and manage your account, including verifying your
              identity and providing access to our services.
            </span>
          </li>
          <li>
            <strong>Connecting Users: </strong>
            <span>
              To enable communication and interaction between students and
              institutions, and to support networking and community building.
            </span>
          </li>
          <li>
            <strong>Content Moderation: </strong>
            <span>
              To ensure the safety and integrity of the App by moderating
              content and addressing inappropriate behavior.
            </span>
          </li>
          <li>
            <strong>Service Improvements: </strong>
            <span>
              To analyze usage patterns, gather feedback, and improve our
              services and features.
            </span>
          </li>
          <li>
            <strong>Communication: </strong>
            <span>
              To send notifications about new features, updates, and changes to
              our Privacy Policy.
            </span>
          </li>
          <li>
            <strong>Legal Compliance: </strong>
            <span>
              To comply with legal obligations and respond to legal requests or
              claims.
            </span>
          </li>
        </ul>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          3. Sharing Your Information
        </h2>
        <p className="text-subparagraph mb-2 text-dark ml-4 md:ml-0">
          We may share your personal data in the following circumstances:
        </p>
        <ul className="list-disc list-inside text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          <li>
            <strong>With Institutions: </strong>
            <span>
              Higher education institutions may access aggregated or personal
              data about their students through their administrator accounts.
              Institutions may use this information for their purposes,
              including recruitment and communication.
            </span>
          </li>
          <li>
            <strong>With Service Providers: </strong>
            <span>
              We use third-party service providers for hosting, email
              communication, chat hosting, and software development. These
              providers are bound by contractual obligations to protect your
              data.
            </span>
          </li>
          <li>
            <strong>For Legal Reasons:</strong>
            <span>
              We may disclose your data to comply with legal obligations,
              respond to legal requests, or protect our rights and safety
            </span>
          </li>
        </ul>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          4. Data Security
        </h2>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          We implement industry-standard security measures to protect your
          personal data from unauthorized access, use, or disclosure. Despite
          our efforts, no data transmission over the internet or electronic
          storage method is completely secure.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          5. Data Retention
        </h2>
        <p className="text-subparagraph mb-2 text-dark ml-4 md:ml-0">
          We retain your personal data for as long as necessary to fulfill the
          purposes for which it was collected. Typically:
        </p>
        <ul className="list-disc list-inside text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          <li>
            <strong>Student Data: </strong>
            <span>
              Stored for five years after your last login or as specified by
              your institution.
            </span>
          </li>
          <li>
            <strong>Administrator Data:</strong>
            <span>
              Stored for as long as the administrator is active with the
              institution and for two years after leaving the institution.
            </span>
          </li>
        </ul>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          6. Your Rights
        </h2>
        <p className="text-subparagraph mb-2 text-dark ml-4 md:ml-0">
          Under applicable data protection laws, you have the following rights:
        </p>
        <ul className="list-disc list-inside text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          <li>
            <strong>Access </strong>
            <span>Request a copy of your personal data.</span>
          </li>
          <li>
            <strong>Correction </strong>
            <span>Request correction of inaccurate or incomplete data.</span>
          </li>
          <li>
            <strong>Deletion </strong>
            <span>
              Request deletion of your personal data under certain conditions
            </span>
          </li>
          <li>
            <strong>Data Portability: </strong>
            <span>
              Request a copy of your data in a structured, commonly used format.
            </span>
          </li>
          <li>
            <strong>Objection: </strong>
            <span>
              Object to the processing of your personal data for specific
              purposes.
            </span>
          </li>
          <li>
            <strong>Restriction </strong>
            <span>
              Request restriction of processing in certain circumstances.
            </span>
          </li>
          <li>
            <strong>Withdraw Consent: </strong>
            <span>
              Withdraw any consent you have given for processing your data.
            </span>
          </li>
        </ul>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          7. Changes to This Privacy Policy
        </h2>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          We may update this Privacy Policy from time to time. Significant
          changes will be communicated to you through notifications in the App
          or other means.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          8. Contact Us
        </h2>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          If you have any questions about this Privacy Policy or wish to
          exercise your rights, please contact us at:
        </p>
        <ul className="list-disc list-inside text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          <div>
            <strong>Company Name: </strong>
            <span>Unimayt</span>
          </div>
          <div>
            <strong>Email Address: </strong>
            <span>info@unimayt.com</span>
          </div>
          <div className="pt-4">
            <strong>Last Updated: </strong>
            <span>17th October, 2024</span>
          </div>
        </ul>
      </div>

      <div className="w-full md:pl-2 md:py-2 ">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicies;
