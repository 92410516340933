import useInstance from "./instance";
import { ENDPOINTS } from "../config/api";

export default function UseChat() {
  const { instance: api } = useInstance();

  const getMessages = async (chatGuid: string) => {
    const response = await api.get(
        ENDPOINTS.CHAT_MESSAGE(chatGuid),
        { withCredentials: true }
    )
    return response;
  }

  const getChats = async () => {
    const response = await api.get(
        ENDPOINTS.CHATS,
        { withCredentials: true }
    )
    return response;
  }

  const postStartChat = async (recipientUserGuid: string) => {
    const response = await api.post(
        ENDPOINTS.CHAT,
        { recipient_user_guid: recipientUserGuid },
        { withCredentials: true }
    )
    return response;
    
  }


  return {
   getMessages,
   getChats,
   postStartChat
  };
}
