import Footer from "../Footer";
import SecondaryHeader from "../header/SecondaryHeader";

const TermsAndConditions = () => {
  return (
    <div>
      <SecondaryHeader search dropdown />

      <div className="container mx-auto px-4 sm:px-8 md:px-12 lg:px-24 xl:px-48 py-0 md:py-0">
        <h1 className="flex items-center justify-center text-smheading md:text-heading font-heading mb-4 md:mb-4 lg:mb-4 mt-2 text-dark">
          Terms And Conditions
        </h1>
        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Definitions
        </h2>
        <ul className="list-disc list-inside text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          <li>
            <strong>Account: </strong>
            <span>
              A personalized section of the Unimayt platform that is assigned to
              each registered User. It allows the User to access the Services
              and interact with the platform. Each User is permitted only one
              Account.
            </span>
          </li>
          <li>
            <strong>Application: </strong>
            <span>
              The Unimayt platform, accessible through web and mobile
              applications available on the Apple App Store and Google Play
              Store.
            </span>
          </li>
          <li>
            <strong>Content: </strong>
            <span>
              All information, data, text, images, videos, or any other
              materials uploaded or transmitted through the platform by Users.
            </span>
          </li>
          <li>
            <strong>Member/ User: </strong>
            <span>
              Any individual who registers for an Account on the Unimayt
              platform, thereby gaining access to the platforms Services.
            </span>
          </li>
          <li>
            <strong>Services: </strong>
            <span>
              All offerings, whether paid or free, available through the Unimayt
              platform that facilitate connections between students and
              universities globally.
            </span>
          </li>
          <li>
            <strong>T&C:</strong>
            <span>
              These Terms and Conditions, which govern the relationship between
              Unimayt and the Users.
            </span>
          </li>
        </ul>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Article 1. Scope of the T&C
        </h2>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          These T&C govern the relationship between Unimayt and the User,
          outlining the rights and obligations arising from the use of the
          platform. By registering for an Account or using the Services, the
          User agrees to these T&C in full.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          1.1 Conflicting Policies
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          In the event of a conflict between these T&C and other policies, such
          as the Privacy Policy or any additional guidelines provided, these T&C
          shall prevail unless stated otherwise.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          1.2 Severability Clause
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Should any provision of these T&C be found invalid or unenforceable by
          law, the remaining provisions will remain in effect unless the invalid
          provision significantly alters the balance of obligations between
          Unimayt and the User.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Article 2. Amendments to the T&C
        </h2>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          2.1 Periodic Updates
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          Unimayt reserves the right to modify or amend these T&C at its
          discretion and without prior notice. Such changes will be communicated
          to Users via email or notifications through the Application. Continued
          use of the Services after any update constitutes the User’s acceptance
          of the revised terms.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          2.2 User Notification
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          It is the User’s responsibility to ensure that their contact
          information is current. If a User does not agree to the amended T&C,
          they must cease use of the Services and deactivate their Account.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Article 3. Eligibility and Registration
        </h2>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          3.1 Age and Legal Capacity
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          Users must be at least 16 years of age to register for an Account. By
          registering, the User confirms that all information provided is
          accurate and up-to-date.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          3.2 Registration Methods
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          Users may register via:
          <div>Direct Sign-Up: Using an email address and password.</div>
          <div>
            Third-Party Sign-In: Via platforms like Facebook or Google, subject
            to their respective terms.
          </div>
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          3.3 Single Account Policy
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Users are only permitted one Account. Multiple accounts may result in
          suspension or termination at the discretion of Unimayt.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Article 4. Services and Features
        </h2>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          4.1 Scope of Services
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          The platform provides a range of tools to connect prospective students
          with universities and fellow students. The Services include but are
          not limited to:
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          4.2 User Conduct
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Users agree to use the platform solely for educational and networking
          purposes. Any unauthorized commercial use, solicitation, or
          inappropriate behavior will lead to immediate suspension of the User's
          account.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Article 5. User Obligations
        </h2>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          5.1 Compliance
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          Users must comply with applicable laws and regulations while using the
          platform. Any activities that violate these laws, including but not
          limited to academic dishonesty, fraud, and discrimination, are
          strictly prohibited.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          5.2 Content Submission
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Users are responsible for the content they upload or share on the
          platform. Unimayt reserves the right to remove any content that
          violates these T&C or applicable laws, or which is deemed
          inappropriate by the platform’s moderation team.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Article 6. Data Protection and Privacy
        </h2>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          Unimayt is committed to protecting the privacy and personal data of
          its Users. By using the platform, Users consent to the collection and
          processing of their personal information as outlined in the Privacy
          Policy, which is available here.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          6.1 Personal Data
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          All personal data collected by Unimayt, including but not limited to
          names, academic records, and geographical information, will be
          processed in compliance with applicable data protection regulations.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          6.2 User Rights
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Users have the right to access, modify, and delete their personal
          data. To exercise these rights, Users may contact Unimayt through the
          provided channels in the Privacy Policy.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Article 7. Intellectual Property
        </h2>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          All content, designs, trademarks, logos, and software related to
          Unimayt are the intellectual property of Unimayt unless otherwise
          specified. Users may not copy, distribute, or modify such content
          without prior written consent.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Article 8. Limitation of Liability
        </h2>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          8.1 No Guarantee of Service Continuity
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          While Unimayt strives to provide uninterrupted access to the platform,
          it cannot guarantee continuous availability. Unimayt is not liable for
          any temporary or permanent disruptions of service.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          8.2 Indemnification
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          The User agrees to indemnify and hold Unimayt harmless from any
          claims, damages, or liabilities arising from the User’s misuse of the
          platform or violation of these T&C.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Article 9. Termination of Services
        </h2>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          Unimayt reserves the right to suspend or terminate any User's Account
          in cases of:
          <ul>
            <li>● Breach of these T&C.</li>
            <li>● Illegal activity.</li>
            <li>● Behavior detrimental to other Users or the platform.</li>
          </ul>
        </p>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Users may terminate their Account at any time by contacting customer
          support. Upon termination, access to the platform will be revoked, but
          certain data may be retained as required by law.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Article 10. Dispute Resolution
        </h2>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          10.1 Governing Law
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          These T&C are governed by and construed in accordance with Indian law,
          excluding any conflict of laws principles.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          10.2 Arbitration
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          In the event of a dispute arising from these T&C, the parties agree to
          attempt to resolve the dispute amicably. If unresolved, the dispute
          will be referred to arbitration in [city], India, in accordance with
          Indian Arbitration and Conciliation Act, 1996.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          Article 11. Miscellaneous
        </h2>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          11.1 Entire Agreement
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          These T&C, along with the Privacy Policy and any other referenced
          documents, constitute the entire agreement between Unimayt and the
          User.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          11.2 Force Majeure
        </h3>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          Unimayt is not liable for any failure to perform due to causes beyond
          its reasonable control, including but not limited to acts of God, war,
          strikes, or other unforeseen events.
        </p>
        <p className="text-subparagraph mb-4 text-dark ml-4 md:ml-0">
          By creating an Account or using the Services, you confirm that you
          have read, understood, and agree to be bound by these Terms and
          Conditions.
        </p>
      </div>

      <div className="w-full md:pl-2 md:py-2 ">
        <Footer />
      </div>
    </div>
  );
};

export default TermsAndConditions;
