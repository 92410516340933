import Footer from "../Footer";
import SecondaryHeader from "../header/SecondaryHeader";

const OurImpacts = () => {
  return (
    <div>
      <SecondaryHeader search dropdown />

      <div className="container mx-auto px-0 sm:px-8 md:px-12 lg:px-24 xl:px-48 py-0 sm:py-8 md:py-0">
        <h1 className="flex items-center justify-center text-smheading md:text-heading font-heading mb-4 sm:mb-10 md:mb-12 lg:mb-4 mt-2 text-dark">
          Our Impact
        </h1>

        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          At Unimayt, our mission is to revolutionize the way students connect
          with universities and each other globally. Our impact is reflected in
          several key areas:
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          1. Empowering Student Success
        </h2>

        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Facilitating Access to Education:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Unimayt bridges the gap between students and educational institutions,
          ensuring that students have access to a wider range of academic
          opportunities. By connecting students with universities that have
          available seats, we help more students find their ideal educational
          path, regardless of geographical constraints.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Supporting International Students:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          We provide a platform tailored to the needs of international students,
          offering resources and support to ease their transition to studying
          abroad. From finding accommodation to connecting with peers and
          university staff, our platform simplifies the complex process of
          moving to a new country for education.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Enhancing Student Networking:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Our platform helps students build meaningful connections with their
          future classmates and others in their academic field. By fostering a
          sense of community before students even arrive on campus, we help them
          build networks that support both their academic and personal growth
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          2. Improving University-Student Engagement
        </h2>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Streamlining Recruitment:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Unimayt helps universities fill vacant seats and optimize their
          student intake processes. By providing universities with access to a
          pool of prospective students, we enable them to make data-driven
          decisions about recruitment and program offerings.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Personalized Student Experience:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Our platform enables universities to understand and address the
          specific needs and preferences of their students. By leveraging data
          and feedback, universities can tailor their services and support to
          improve student satisfaction and retention.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Building Stronger University Communities:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Through our platform, universities can engage with students more
          effectively, fostering a stronger sense of community within their
          institutions. This engagement helps universities build better
          relationships with their students and enhances the overall student
          experience.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          3. Driving Innovation in Education
        </h2>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Advancing Technology in Education:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Unimayt leverages cutting-edge technology to enhance the student
          experience. From advanced algorithms that match students with
          universities to seamless integration of support services, we are at
          the forefront of technological innovation in the education sector.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Promoting Ethical Data Practices:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          We are committed to maintaining the highest standards of data ethics,
          ensuring that student data is handled with the utmost care and
          transparency. By implementing strong data protection measures and
          ethical practices, we set a benchmark for responsible data management
          in the education industry
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Encouraging Sustainable Practices:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Our platform promotes sustainability by reducing the need for physical
          paperwork and streamlining administrative processes. By digitizing and
          automating many aspects of student recruitment and engagement, we
          contribute to a more sustainable and efficient education ecosystem.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          4. Fostering Global Connections
        </h2>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Creating a Global Network:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          Unimayt’s platform connects students from around the world,
          facilitating cross-cultural exchanges and fostering a global network
          of learners. By bringing together students from diverse backgrounds,
          we help promote cultural understanding and collaboration.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Supporting Diverse Educational Pathways:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-00">
          Our platform accommodates a wide range of educational pathways and
          institutions, from traditional universities to alternative education
          options. This diversity allows students to explore various
          opportunities and find the best fit for their academic and career
          goals.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Enhancing Accessibility:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          We are dedicated to making education accessible to students from all
          backgrounds, including those from underrepresented or disadvantaged
          communities. By providing a platform that is inclusive and supportive,
          we help level the playing field and create opportunities for all
          students.
        </p>

        <h2 className="text-subheading font-heading mb-2 text-dark ml-4 md:ml-0">
          5. Measuring Our Impact
        </h2>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Tracking Success Metrics:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          We continuously measure the impact of our platform through various
          metrics, including student satisfaction, university engagement, and
          overall platform usage. By analyzing this data, we can refine our
          services and ensure that we are effectively meeting the needs of our
          users.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Gathering Feedback:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          We actively seek feedback from students, universities, and partners to
          understand the effectiveness of our platform and identify areas for
          improvement. This feedback loop helps us stay responsive to the needs
          of our users and make data-driven decisions.
        </p>
        <h3 className="text-paragraph font-heading mb-2 text-dark ml-4 md:ml-0">
          Reporting and Accountability:
        </h3>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          We are committed to transparency and accountability in our operations.
          We regularly report on our impact, including key achievements,
          challenges, and progress towards our goals. This transparency ensures
          that we remain accountable to our users and stakeholders.
        </p>
        <p className="text-subparagraph mb-8 text-dark ml-4 md:ml-0">
          By focusing on these areas, Unimayt is making a meaningful impact on
          the education sector, enhancing the experiences of students and
          universities alike. We are proud of the positive changes we are
          driving and remain dedicated to advancing our mission of connecting
          students with opportunities worldwide.
        </p>
      </div>

      <div className="w-full md:pl-2 md:py-2 ">
        <Footer />
      </div>
    </div>
  );
};

export default OurImpacts;
