import amber from "../../assets/Logos/PartnerLogos/amber.png";
import prodigy from "../../assets/Logos/PartnerLogos/prodigy.jpg";
import trip from "../../assets/Logos/PartnerLogos/trip.png";
import bokz from "../../assets/Logos/PartnerLogos/bokz.png";
import Uhomes from "../../assets/Logos/PartnerLogos/uhomes.png";
import { InfiniteMovingCards } from "../ui/infinite-moving-cards";

const companies = [
  {
    name: "Amber",
    logo: amber,
    link: "https://amberstudent.com/?utm_source=unimayt&utm_medium=website&utm_content=homepage",
  },
  {
    name: "Prodigy Finance",
    logo: prodigy,
    link: "https://prodigyfinance.com/?utm_source=unimayt&utm_medium=website&utm_content=homepage&utm_campaign=partners-unimayt-referral-2024",
  },
  {
    name: "Trip.com",
    logo: trip,
    link: "https://www.trip.com/partners/account/profile?utm_source=unimayt&utm_medium=website&utm_content=homepage",
  },
  {
    name: "Bokz",
    logo: bokz,
    link: "https://bokz.co.uk/product/the-essential/?company_name=Unimayt",
  },
  {
    name: "Uhomes",
    logo: Uhomes,
    link: "https://en.uhomes.com/referral/partnerShare?xcode=000ac268a5c7a3e821e7",
  },
];

const Partnership = () => {
  return (
    <>
      <div className="bg-unimaytLight flex justify-center items-center pt-0 lg:pt-56 md:-mt-40">
        <section>
          <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:pb-16 pt-4 lg:px-0">
            <div className="flex items-center justify-center mb-8">
              <div className="flex-grow h-px bg-white mr-0 md:ml-6 ml-2"></div>
              <h2 className="text-smheading lg:text-heading font-heading text-dark px-4 whitespace-nowrap">
                Partnerships
              </h2>
              <div className="flex-grow h-px bg-white ml-0 md:mr-6 mr-2"></div>
            </div>
            <div className="mx-auto mb-8 md:mb-20 max-w-screen-sm">
              <p className="text-light text-paragraph">
                Explore our current partnerships and discover how we are shaping
                the future through shared vision and teamwork.
              </p>
            </div>

            <div className="grid gap-8 lg:gap-16 md:grid-cols-6 items-center justify-center lg:ml-80">
              <div className="rounded-md md:w-[700px] text-center w-11/12 flex flex-col antialiased items-center justify-center relative overflow-x-hidden">
                <div className="w-full">
                  <InfiniteMovingCards
                    items={companies}
                    direction="right"
                    pauseOnHover={false}
                    speed="normal"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Partnership;
