import { create } from "zustand";
import { User } from "../types/UserProfile";

export const useUserStore = create((set: any) => ({
  users: [] as User[],
  selectedUser: null as any,
  userToInitiateChat: {} as User,
  chatRoomOpen: null as any,
  chatGuid: null as any,
  setChatGuid: (chat_guid: string) => set({ chatGuid: chat_guid }),
  setUsers: (users: any) => set({ users }),
  setSelectedUser: (user: any) => set({ selectedUser: user }),
  setChatRoomOpen: (chatRoomOpen: any) => set({ chatRoomOpen: chatRoomOpen }),
  setUserToInitiateChat: (user: any) => set({ userToInitiateChat: user }),
}));
