import useInstance from "./instance";
import { ENDPOINTS } from "../config/api";

export default function UseUsers() {
  const { instance: api } = useInstance();

  const getUsers = async () => {
    const response = await api.get(ENDPOINTS.USERS, { withCredentials: true });
    return response;
  };

  const getUsersbyId = async ({ queryKey }: any) => {
    const user_guid = queryKey[1];
    const response = await api.get(ENDPOINTS.USER_BY_ID(user_guid), {
      withCredentials: true,
    });
    return response.data;
  };

  const updateProfile = async (obj: any) => {
    const response = await api.patch(ENDPOINTS.UPDATE_USER, obj, {
      withCredentials: true,
    });
    return response.data;
  };

  const uploadProfileImage = async (obj: any) => {
    const response = await api.post(ENDPOINTS.PROFILE_IMAGE, obj, {
      withCredentials: true,
    });
    return response.data;
  };

  return {
    getUsers,
    getUsersbyId,
    updateProfile,
    uploadProfileImage,
  };
}
