import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { Input } from "../../ui/Input";
import { Label } from "../../ui/Label";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../ui/card";
import { useNavigate } from "react-router-dom"; // Updated import
import SecondaryHeader from "../../layout/header/SecondaryHeader";
import { z } from "zod";
import { resetPasswordSchema } from "../../../zodSchema/NewPasswordSchema";
import UseAuth from "./../../../services/useAuth";
import toast from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";

type ResetPasswordSchemaType = z.infer<typeof resetPasswordSchema>;

const NewPassword = () => {
  const navigate = useNavigate();

  const { resetPassword } = UseAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordSchemaType>({
    resolver: zodResolver(resetPasswordSchema),
  });

  const resetMutation = useMutation({
    mutationFn: resetPassword,
    onSuccess: async (data) => {
      toast.success("Reset Done!");
      navigate("/login", { replace: true });
    },
    onError: (err: any) => {
      toast.error(`${err.response.data.detail}`);
    },
  });

  const getToken = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("token");
  };

  const onSubmit = (data: ResetPasswordSchemaType) => {
    console.log(11);
    let obj = {
      token: getToken(),
      new_password: data.new_password,
    };
    resetMutation.mutate(obj);
  };

  return (
    <div>
      <div className="sticky top-0 z-10">
        <SecondaryHeader />
      </div>
      <div className="flex flex-col justify-center items-center px-4 h-screen">
        <Card className="w-full max-w-md md:p-2 p-0 rounded-2xl shadow-2xl">
          <CardHeader>
            <CardTitle className="text-center ">
              Reset Account Password
            </CardTitle>
            <CardDescription className="text-center">
              Check your email for Link.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 mb-6">
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1">
                  <Label htmlFor="newPassword">New Passwod</Label>
                  <Input
                    id="newPassword"
                    type="password"
                    placeholder="Enter new password"
                    {...register("new_password", { required: true })}
                    className="w-full"
                  />
                  {errors.new_password && (
                    <p className="text-red-500 text-xs">
                      {errors.new_password.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col space-y-1">
                  <Label htmlFor="confirmPassword">Confirm Password</Label>
                  <Input
                    id="confirmPassword"
                    type="password"
                    placeholder="Confirm new password"
                    {...register("confirmPassword", { required: true })}
                    className="w-full"
                  />
                  {errors.confirmPassword && (
                    <p className="text-red-500 text-xs">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="hover:scale-105 cursor-pointer duration-200 btn ease-in-out transition-all w-full bg-Button my-5 rounded-3xl"
              >
                Confirm
              </button>

              <CardDescription className="text-center">
                If you don’t see the email in your inbox, check your spam
                folder. If it’s not there, the email address may not be
                confirmed, or it may not match an existing Unimayt account.
              </CardDescription>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default NewPassword;
