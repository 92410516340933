import { Avatar, AvatarFallback, AvatarImage } from "../../ui/Avatar";

const ChatList = ({ user }: any) => {
  return (
    <>
      <div className="p-4 border-b hover:bg-opacity-55 cursor-pointer">
        <div className="flex items-center space-x-4">
          <div className="relative">
            <Avatar className="h-10 w-10 border-2 border-dark">
              <AvatarImage src={user.user_image || ""} alt={user.first_name} />
              <AvatarFallback>
                <img
                  src={
                    user?.gender === "Female"
                      ? "/icons/female.png"
                      : "/icons/male.png"
                  }
                  alt="Profile"
                  className="w-full h-full rounded-full object-cover"
                />
              </AvatarFallback>
            </Avatar>
            <span
              className={`absolute bottom-0 right-0 block h-3 w-3 rounded-full ring-2 ring-background ${
                user.isOnline ? "bg-dark" : "bg-unimaytLight"
              }`}
            />
          </div>
          <div className="flex-grow min-w-0">
            <div className="flex justify-between items-baseline">
              <h3 className="text-paragraph font-semibold truncate">
                {user.first_name} {user.last_name}
              </h3>
            </div>
            <p className="text-paragraph text-muted-foreground truncate">
              {user.username}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatList;
