import EnterEmail from "../components/shared/passwordReset/EnterEmail";

const ResetPassword = () => {
  return (
    <div>
      <EnterEmail />
    </div>
  );
};

export default ResetPassword;
