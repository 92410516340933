import React, { useState } from "react";

const MiniJourney: React.FC = () => {
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  // const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});

  // const handleToggleDropdown = (index: number) => {
  //   setOpenDropdown(openDropdown === index ? null : index);
  // };

  // const handleCheckboxChange = (index: number, subIndex: number) => {
  //   setCheckedItems((prev) => ({
  //     ...prev,
  //     [`${index}-${subIndex}`]: !prev[`${index}-${subIndex}`],
  //   }));
  // };

  return (
    <div className="bg-gray-100 py-4">
      <div className="text-smheading lg:text-heading font-heading mb-4 text-dark text-left pr-4 lg:pr-14 md:pr-0 md:mt-3 md:ml-6 ml-8 mt-0">
        Journey
      </div>
      <div className="relative space-y-6 ml-4 md:ml-8">
        <div className="absolute inset-0 ml-6 md:ml-5 h-full w-0.5 bg-gray-600"></div>
        {[...Array(11)].map((_, index) => (
          <div className="relative" key={index}>
            <div className="flex items-start justify-between mb-8">
              <div className="flex items-center space-x-4 w-full">
                {/* Icon */}
                <div className="flex-shrink-0 flex items-center justify-center w-10 h-10 rounded-full bg-light shadow ml-1">
                  <svg
                    style={{ fill: index === 0 ? "#4c3d6d" : "#b8acd0" }} // Use hex codes directly
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                  >
                    <path d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" />
                  </svg>
                </div>

                {/* Title Text */}
                <div className="text-slate-500 flex-grow flex items-center">
                  <span className="text-Button font-heading md:text-subheading text:paragraph italic break-words px-1">
                    {
                      [
                        "SHORTLIST COURSES & COLLEGES",
                        "TESTS (IELTS, GRE, GMAT, etc.)",
                        "DOCUMENT PREPARATION (SOP, LOR, etc.)",
                        "UNIVERSITY APPLICATION",
                        "MAYTING (Find other students going to same university)",
                        "FINANCES (Sanction Letter, Proof of Funds - Savings, Loan, Guarantor etc.)",
                        "ACCOMODATIONS",
                        "VISA",
                        "AIRTICKET",
                        "FOREX",
                        "START NEXT CHAPTER IN YOUR LIFE",
                      ][index]
                    }
                  </span>
                </div>
              </div>
            </div>

            {openDropdown === index && (
              <div className="pl-2 text-dark ml-14 md:ml-44">
                {/* Subtopics here */}
                {/* <div className="relative">
                  {[ 
                    "Review candidate resumes",
                    "Establish the criteria",
                    "Create a candidate shortlisting",
                  ].map((subtopic, subIndex) => (
                    <div
                      className="flex items-center space-x-4 mb-2"
                      key={subIndex}
                    >
                      <span className="text-slate-500 font-heading">
                        {subtopic}
                      </span>
                      <input
                        type="checkbox"
                        checked={!!checkedItems[`${index}-${subIndex}`]}
                        onChange={() => handleCheckboxChange(index, subIndex)}
                        className="w-4 h-4 accent-Button"
                        style={{
                          accentColor: checkedItems[`${index}-${subIndex}`]
                            ? "Button"
                            : "Button", // Use hex code for gray-300
                        }}
                      />
                    </div>
                  ))}
                </div> */}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MiniJourney;
