import axios from "axios";
import { API_BASE_URL } from "../config/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";

const Instance = () => {
  const navigate = useNavigate();

  const instance = axios.create({
    baseURL: API_BASE_URL,
  });

  const refreshTokens = () => {
    return instance
      .post("/refresh/", {}, { withCredentials: true })
      .then((response) => {
        return response.data;
      });
  };

  // Response interceptor
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const initialRequest = error.config;
      if (error.response.status === 401 && !initialRequest._retry) {
        initialRequest._retry = true;
        return refreshTokens()
          .then(() => {
            return instance(initialRequest);
          })
          .catch((err) => {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("exp");
            navigate("/login");
            return Promise.reject(err);
          });
      }
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      switch (error.response.status) {
        case 401:
          navigate("/login");
          break;

        case 422:
          break;

        case 409:
          toast.error(error.response.data.message);
          break;

        case 500:
          break;

        default:
          break;
      }
      return Promise.reject(error);
    }
  );
  return { instance };
};

export default Instance;
